<template>
  <div class="home">
    <div class="head">
      <div class="left">
        <img src="../assets/image/location.png" alt="" />
        <span>重庆</span>
      </div>
      <!-- <div class="right" @click="show = true">
        <span>{{ marketName }}</span>
        <img src="../assets/image/switch.png" alt="" />
      </div> -->
    </div>
    <van-swipe class="mySwipe" :autoplay="3000" indicator-color="#D1D1D1">
      <van-swipe-item v-for="item in bannerList" :key="item.banner_id">
        <img class="cover" :src="item.url" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="notice">
      <div class="left">
        <img src="../assets/image/notice.png" alt="" />
      </div>
      <div class="segmentation"></div>
      <div class="right">
        <van-swipe class="noticeSwipe" :autoplay="3000" :show-indicators="false" :vertical="true">
          <van-swipe-item v-for="item in msgLogList" :key="item.property_log_id"
            @click="$router.push({ path: '/notice/index' })">
            <!-- <div class="notice"> -->
            <span class="newdot" v-if="item.log_status == 0"></span>
            <p class="content">{{ item.property_content }}</p>
            <!-- </div> -->
          </van-swipe-item>
          <van-swipe-item class="empty-notice" v-if="msgLogList.length <= 0">暂无公告~</van-swipe-item>
        </van-swipe>
      </div>
      <div class="next">
        <!-- <img src="../assets/image/next.png" alt=""> -->
      </div>
    </div>
    <div class="boxModule">
      <div class="left" @click="pay">
        <p class="name">物业费查询/缴纳</p>
        <img src="../assets/image/chaxuan.png" alt="" />
      </div>
      <div class="right">
        <div class="list" @click="$router.push({ path: '/repair/apply' })">
          <p class="name">申请报修</p>
          <img src="../assets/image/baoxiu1.png" alt="" />
        </div>
        <div class="list" @click="$router.push({ path: '/notice/index' })">
          <div class="content">
            <p class="name">消息通知</p>
            <p class="desc" v-if="total > 0">
              收到 <span>{{ total }}</span> 条消息
            </p>
          </div>
          <img src="../assets/image/msg.png" alt="" />
        </div>
      </div>
    </div>
    <div class="boxList">
      <div class="list" @click="$router.push({ path: '/property/index' })">
        <p class="name">联系物业</p>
        <img src="../assets/image/contact.png" alt="" />
      </div>
      <!-- path:'/repair/apply' -->
      <div class="list" @click="$router.push({ path: '/features/index' })">
        <p class="name">更多</p>
        <img src="../assets/image/feature.png" alt="" />
      </div>
    </div>
    <van-popup v-model:show="show" position="bottom" round :lock-scroll="false">
      <van-picker title="切换市场" :columns="columns" :columns-field-names="customFieldName" @cancel="show = false"
        @confirm="confirmPicker" />
    </van-popup>
    <Navigation :index="0"></Navigation>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';

export default {
  name: 'Home',
  components: {
    Navigation
  },
  data () {
    return {
      bannerList: [{ url: require('../assets/image/banner1.png') }],
      msgLogList: [],
      columns: [],
      marketName: '',
      customFieldName: {
        text: 'marketName'
      },
      show: false,
      total: 0,
      userInfo: null
    };
  },
  created () {
    let userInfo = window.localStorage.getItem('propertyUserInfo') || '';
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      this.marketName = this.userInfo.marketName;
    }
    this.getBannerList();
    this.getMsgLog();
    // this.getMessageNumber()
    this.getMarketList();
  },
  methods: {
    pay () {
      this.$toast('请联系物业人员');
      return;
      this.$router.push({ path: '/contribute/index' });
    },
    getMarketList () {
      this.$require.HttpPost('/shop/getMarket', {}, { isTokn: false, isURl: true }).then((res) => {
        this.columns = res.data || [];
      });
    },
    getUserInfo () {
      this.$require.HttpPost('/shop/info', {}, { isTokn: false, isURl: true }).then((res) => {
        window.localStorage.setItem('propertyUserInfo', JSON.stringify(res.data));
        this.userInfo = res.data;
        console.log(res.data);
      });
    },
    getBannerList () {
      // console.log(process.env.VUE_APP_IMG_URL);
      this.$require.HttpPost('/h5/banner/list').then((res) => {
        if (res.data && res.data.list) {
          res.data.list.forEach((item) => {
            if (!item.url.includes('http')) {
              item.url = process.env.VUE_APP_IMG_URL + item.url;
            }
          });
        }

        this.bannerList = res.data.list || [{ url: require('../assets/image/banner1.png') }];
      });
    },
    getMsgLog () {
      this.$require.HttpPost('/h5/message/getNoReadList').then((res) => {
        if (res.data) {
          this.msgLogList = res.data.list || [];
          this.total = res.data.total;
        }
      });
    },
    getMessageNumber () {
      this.$require.HttpPost('/h5/message/messageNumber').then((res) => {
        this.total = res.data;
      });
    },
    confirmPicker (event) {
      console.log(event);
      this.marketName = event.marketName;
      this.$require
        .HttpPost(
          '/shop/setMarket',
          { selectMarketId: event.marketId },
          { isTokn: false, isURl: true }
        )
        .then(() => {
          this.show = false;
          this.getUserInfo();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0 24px 100px 24px;

  .head {
    height: 114px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      img {
        width: 32px;
        height: 32px;
      }

      span {
        font-size: 32px;
        color: #000000;
        margin-left: 19px;
      }
    }

    .right {
      height: 70px;
      padding: 0 33px;
      background: #f8f8f8;
      border-radius: 35px;
      display: flex;
      align-items: center;

      span {
        font-size: 32px;
        color: #000000;
        margin-right: 22px;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .mySwipe {
    .cover {
      height: 339px;
      width: 100%;
    }
  }

  .notice {
    margin-top: 30px;
    padding: 20px 10px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      img {
        width: 67px;
        height: 70px;
      }
    }

    .segmentation {
      width: 2px;
      height: 28px;
      background: #b9b9b9;
      margin: 0 20px;
    }

    .right {
      .empty-notice {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #529100;
        line-height: 70px;
        margin: 0;
        // text-align: center;
      }

      .noticeSwipe {
        width: 510px;
        height: 70px;
        line-height: 70px;
        // .notice{
        //   position: relative;

        .content {
          font-size: 32px;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // position: absolute;
          // top: 0;
          // left: 0;
        }

        .newdot {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: red;
          position: absolute;
          right: 15px;
          top: 0;
        }

        // }
      }
    }

    .next {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .boxModule {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .left {
      width: 341px;
      height: 380px;
      background: #ffffff;
      box-shadow: 0 0 25px 0 rgba(57, 57, 57, 0.06);
      border-radius: 15px;
      padding: 30px 20px;
      box-sizing: border-box;

      .name {
        font-size: 32px;
        color: #000000;
      }

      img {
        width: 235px;
        height: 234px;
        display: block;
        margin: 52px auto 0;
      }
    }

    .right {
      width: 341px;
      height: 380px;

      .list {
        width: 100%;
        height: 180px;
        background: #ffffff;
        box-shadow: 0 0 25px 0 rgba(57, 57, 57, 0.06);
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        justify-content: space-between;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .name {
          font-size: 32px;
          color: #000000;
        }

        img {
          width: 125px;
          height: 125px;
        }

        .content .desc {
          color: #b2b2b2;
          font-size: 24px;

          span {
            color: #ff6238;
          }
        }
      }
    }
  }

  .boxList {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .list {
      display: flex;
      width: 341px;
      height: 180px;
      justify-content: space-between;
      background: #ffffff;
      box-shadow: 0 0 25px 0 rgba(57, 57, 57, 0.06);
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;

      .name {
        font-size: 32px;
        color: #000000;
      }

      img {
        width: 125px;
        height: 125px;
      }
    }
  }

  .marketModel {
    width: 702px;
    background: #ffffff;
    border-radius: 30px;

    .title {
      height: 120px;
      line-height: 120px;
      border-bottom: 1px solid #efefef;
      text-align: center;
      font-size: 32px;
      color: #000000;
    }

    .content {
      text-align: center;
      padding: 10px 0;

      .list {
        font-size: 28px;
        color: #b9b9b9;
        margin-top: 40px;

        &.act {
          font-size: 32px;
          color: #000000;
        }
      }
    }

    .base {
      margin-top: 70px;
      display: flex;
      justify-content: center;
      padding-bottom: 50px;

      .btn {
        width: 250px;
        height: 96px;

        &:last-child {
          margin-left: 60px;
        }
      }
    }
  }
}
</style>
